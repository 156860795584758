import httpClient from '@/libs/http-client'

// 컴퍼니 서브포인트 적립내역
export function companySubPointList (data) {
  const url = '/api/admin/partner/creditsubPoint/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 서브포인트 적립내역
export function companySubPointMyList (data) {
  const url = '/api/admin/credit/subPoint/myList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 서브포인트 적립내역
export function companySubPointBotList (data) {
  const url = '/api/admin/credit/subPoint/botList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 게임별 서브포인트 요율 설정
export function companyBiSubPointRates (data) {
  const url = '/api/admin/partner/modify/biSubPointRates'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 상세 정보
export function companyDetail (data) {
  const url = '/api/admin/partner/detail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 정보 수정
export function companyModify (data) {
  const url = '/api/admin/partner/modify'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 컴퍼니 정보 수정
export function companyPassUpdate (data) {
  const url = '/api/admin/partner/passChg'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 정보 수정
export function companyOtpUpdate (data) {
  const url = '/api/admin/partner/otpChg'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function companyRegist (data) {
  const url = '/api/admin/partner/signup'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function creditMyList (data) {
  const url = '/api/admin/credit/myList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
export function creditBotList (data) {
  const url = '/api/admin/credit/botList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 컴퍼니 상세
// 컴퍼니 정보 수정
export function compBasicInfoModify (data) {
  const url = '/api/admin/partner/modify/compInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 기능설정
export function compSiteSettingModify (data) {
  const url = '/api/admin/partner/modify/siteSettings'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 pt 설정
export function compPtSettingModify (data) {
  const url = '/api/admin/partner/modify/creditSettings'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 게임별 pt 설정
export function compGamesRateModify (data) {
  const url = '/api/admin/partner/modify/creditRates'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 게임별 베팅 설정
export function compGamesBetModify (data) {
  const url = '/api/admin/partner/modify/betSettings'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 컴퍼니 게임별 보험 서브포인트 설정
export function compGamesBiSubRateModify (data) {
  const url = '/api/admin/partner/modify/biSubPointRates'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// PT 지급
export function setPTCreditIn (data) {
  const url = '/api/admin/credit/topDeposit'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setPTCreditOut (data) {
  const url = '/api/admin/credit/topWithdraw'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getVendorRating (data) {
  const url = '/api/admin/partner/getTripleGameRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setVendorRating (data) {
  const url = '/api/admin/partner/setTripleGameRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
