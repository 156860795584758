import httpClient from '@/libs/http-client'

// 계정 보유머니
export function settingAccount (data) {
  const url = '/api/admin/setting/account'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 계정 보유머니
export function settingLogList (data) {
  const url = '/api/admin/setting/logList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 사이트 업체 은행
export function comBank (data) {
  const url = '/api/admin/setting/bank'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 레벨별설정
// 계좌등급 목록
export function settingLvAccount (data) {
  const url = '/api/admin/setting/level/account'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 계좌등급 수정
export function settingLvAccountSave (data) {
  const url = '/api/admin/setting/level/accountSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 베팅제한
export function settingLvBetLimit (data) {
  const url = '/api/admin/setting/level/betLimit'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 베팅제한 수정
export function settingLvBetLimitSave (data) {
  const url = '/api/admin/setting/level/betLimitSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 레벨별 도메인
export function settingLvDomain (data) {
  const url = '/api/admin/setting/level/domain'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 레벨별 도메인 수정
export function settingLvDomainSave (data) {
  const url = '/api/admin/setting/level/domainSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 레벨별 출금레벨
export function settingLvOutPwd (data) {
  const url = '/api/admin/setting/level/outPwd'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 레벨별 출금레벨 수정
export function settingLvOutPwdSave (data) {
  const url = '/api/admin/setting/level/outPwdSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 레벨별 낙첨이벤트
export function settingLvfailPoint (data) {
  const url = '/api/admin/setting/level/failPoint'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 레벨별 낙첨이벤트 수정
export function settingLvfailPointSave (data) {
  const url = '/api/admin/setting/level/failPointSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 점검
export function settingCheckList (data) {
  const url = '/api/admin/setting/siteCheckList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function settingCheckDetail (data) {
  const url = '/api/admin/setting/siteCheckDetail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 점검 등록
export function settingCheckInsert (data) {
  const url = '/api/admin/setting/siteCheckSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 점검종료
export function siteCheckOff (data) {
  const url = '/api/admin/setting/siteCheckOff'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 리스트 점검종료
export function siteCheckOffByIdx (data) {
  const url = '/api/admin/setting/siteCheckOffByIdx'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 차단유형
export function settingBlock (data) {
  const url = '/api/admin/setting/block'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 차단유형수정
export function settingBlockSave (data) {
  const url = '/api/admin/setting/blockSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getDomainList (data) {
  const url = '/api/admin/setting/domainPartnerList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function saveDomain (data) {
  const url = '/api/admin/setting/domainPartnerSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function deleteDomain (data) {
  const url = '/api/admin/setting/domainPartnerDelete'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getSettingUserLevelList (data) {
  const url = '/api/admin/setting/level/chargingPointSettingInfo'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function updateSettingUserLevel (data) {
  const url = '/api/admin/setting/level/chargingPointSettingSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function parseApi (data) {
  const url = '/api/admin/setting/parseUseUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
