<template>
   <section class="min-w1600">
      <div class="searchwrap">
         <section class="page-container">
            <div class="page-contents">
               <article class="page-contents-wrap">
                 <div class="sub-title">
                    <h3>{{ $t('common.siteInfo') }}</h3>
                 </div>
                 <div class="page-content">
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.id') }}</div>
                       <div class="item-content">
                         {{ siteData.memId }}
                       </div>
                     </div>
                   </div>
                   <!-- <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">닉네임</div>
                       <div class="item-content">
                         {{ siteData.memNick }}
                       </div>
                     </div>
                   </div> -->
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t("common.credit") }}</div>
                       <div class="item-content">
                         {{ siteData.creditAmt }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.tlastActhu') }}</div>
                       <div class="item-content">
                         {{ siteData.totalCashAmtEve }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.totalAct') }}</div>
                       <div class="item-content">
                         {{ siteData.totaCashAmt }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('totalInfo.gameMoney') }}</div>
                       <div class="item-content">
                         {{ siteData.totalGameMoneyAmt }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.memTotal') }}</div>
                       <div class="item-content">
                         {{ siteData.memTotalCntUse }} &nbsp;<!--span>({{ siteData.memCntUse }}+{{ siteData.memBlackCntUse }})</span-->
                       </div>
                     </div>
                   </div>
                 </div>
               </article>
               <!--article class="page-contents-wrap">
                 <div class="sub-title">
                    <h3>{{ $t('common.sitePresent') }}</h3>
                 </div>
                 <div class="page-content">
                   <div class="item-row-container" v-if="siteData.progBetAmtSenior||siteData.progBetAmtSenior===0">
                     <div class="item-wrap">
                       <div class="item-title">시니어 진행중인 베팅금액</div>
                       <div class="item-content">
                         {{ siteData.progBetAmtSenior }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container" v-if="siteData.progBetAmtMaster || siteData.progBetAmtMaster === 0">
                     <div class="item-wrap">
                       <div class="item-title">마스터 진행중인 베팅금액</div>
                       <div class="item-content">
                         {{ siteData.progBetAmtMaster }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container" v-if="siteData.progBetAmtAgency || siteData.progBetAmtAgency === 0">
                     <div class="item-wrap">
                       <div class="item-title">시니어 진행중인 베팅금액</div>
                       <div class="item-content">
                         {{ siteData.progBetAmtAgency }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container" v-if="siteData.progBetAmtUse || siteData.progBetAmtUse === 0">
                     <div class="item-wrap">
                       <div class="item-title">회원 진행중인 베팅금액</div>
                       <div class="item-content">
                         {{ siteData.progBetAmtUse }}
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">시니어 전체 수</div>
                       <div class="item-content">
                         {{ siteData.memTotalCntSenior }}
                       </div>
                     </div>
                   </div> 
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.masterTotal') }}</div>
                       <div class="item-content">
                         {{ siteData.memTotalCntMster }}&nbsp;<span>({{ siteData.memCntMster }}+{{ siteData.memBlackCntMster }})</span>
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.agenyTotal') }}</div>
                       <div class="item-content">
                         {{ siteData.memTotalCntAgency }} &nbsp;<span>({{ siteData.memCntAgency }}+{{ siteData.memBlackCntAgency }})</span>
                       </div>
                     </div>
                   </div>
                 </div>
               </article-->
               <article class="page-contents-wrap">
                 <div class="sub-title">
                    <h3>{{ $t('common.pwChange') }}</h3>
                    <button type="button" class="btn-layout btn-grey" @click="passUpdate">{{ $t('button.save') }}</button>
                 </div>
                 <div class="page-content">
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.nowPw') }}</div>
                       <div class="item-content">
                         <input ref="currentPass" type="password" v-model="siteData.memPass"/>
                         <!--<button type="button" class="btn-layout btn-blue" @click="inputTypeToggle('currentPass')">{{ currentPassText }}</button>-->
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.newPw') }}</div>
                       <div class="item-content">
                         <input type="password" v-model="pw.new" />
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.newPwCheck') }}</div>
                       <div class="item-content">
                         <input type="password" v-model="pw.check" />
                       </div>
                     </div>
                   </div>
                 </div>

                 <!--div class="sub-title mt-15">
                    <h3>{{ $t('common.otp') }}</h3>
                 </div>
                 <div class="page-content">
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title">{{ $t('common.otpCheck') }}</div>
                       <div class="item-content">
                          <div class="toggle-item">
                            <input type="checkbox" class="set-switch" />
                          </div>
                       </div>
                     </div>
                   </div>
                 </div-->
               </article>
               <!--article class="page-contents-wrap">
                 <div class="sub-title-wrap fx-col-sbw">
                   <sub-title :text="'보안코드 변경'" />
                   <button type="button" class="btn-layout btn-blue" @click="otpUpdate">{{ $t('button.save') }}</button>
                 </div>
                 <div class="page-content">
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title" style="flex: 0 0 8.5%">현재 보안코드</div>
                       <div class="item-content" style="gap: 8px">
                         <input ref="currentOtp" type="password" :value="siteData.adminOtp" readonly />
                         <button type="button" class="btn-layout btn-blue" @click="inputTypeToggle('currentOtp')">{{ currentOtpText }}</button>
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title" style="flex: 0 0 8.5%">새 보안코드</div>
                       <div class="item-content">
                         <input type="password" v-model="otp.new" />
                       </div>
                     </div>
                   </div>
                   <div class="item-row-container">
                     <div class="item-wrap">
                       <div class="item-title" style="flex: 0 0 8.5%">보안코드 확인</div>
                       <div class="item-content">
                         <input type="password" v-model="otp.check" />
                       </div>
                     </div>
                   </div>
                 </div>
               </article-->
            </div>
            <div class="center"><btn-regist @click="regist" /></div>
         </section>
      </div>
   </section>
</template>

<script>
import lodash from "lodash";
import { getSiteData } from "@/libs/auth-helper";
import subTitle from "@/components/main/PageSubTitle";
import { getCode } from "@/api/common";
import { companyDetail, companyModify, companyPassUpdate, companyOtpUpdate } from "@/api/company";
import { settingAccount } from "@/api/setting";
import { replaceDateT, numberWithCommas } from "@/libs/utils.js";

export default {
  name: "AccBalance",
  components: {
    subTitle
  },
  data() {
    return {
      siteInfo: {},
      siteDetail: {},
      siteData: {},
      reqData: {
        siteId: "",
        memId: ""
      },
      pw: {
        new: "",
        check: ""
      },
      otp: {
        new: "",
        check: ""
      },
      currentOtpText: "보기",
      currentPassText: "보기"
    };
  },
  methods: {
    async passUpdate() {
      const pw = lodash.cloneDeep(this.pw);
      console.log(pw);
      if (!pw.new || !pw.check) {
        alert("비밀번호 정보를 모두 입력해주세요.");
        return;
      }
      if (pw.new !== pw.check) {
        alert("비밀번호가 다릅니다. 다시 입력해주세요.");
        return;
      }
      if (pw.new === this.siteData.memPass) {
        alert("현재 비밀번호와 같습니다. 다시 입력해주세요.");
        return;
      }
      const req = {
        siteId: this.siteData.siteId,
        memId: this.siteData.memId,
        sitePass: this.siteData.memPass,
        newSitePass: this.pw.new
      };
      console.log(req);
      const trigger = confirm("비밀번호를 변경하시겠습니까?");
      if (!trigger) {
        return;
      }
      const res = await companyPassUpdate(req);
      if (res.resultCode === "0") {
        alert("비밀번호 변경 완료");
        this.setTableData();
        this.pw.new = "";
        this.pw.check = "";
      } else {
        alert("비밀번호 변경 실패, 다시 시도해주세요.");
      }
    },
    async otpUpdate() {
      const otp = lodash.cloneDeep(this.otp);
      console.log(otp);
      if (!otp.new || !otp.check) {
        alert("보안코드 정보를 모두 입력해주세요.");
        return;
      }
      if (otp.new !== otp.check) {
        alert("보안코드가 다릅니다. 다시 입력해주세요.");
        return;
      }
      const req = {
        siteId: this.siteData.siteId,
        memId: this.siteData.memId,
        adminOtp: this.siteData.adminOtp,
        newSiteOtp: this.otp.new
      };
      console.log(req);
      const trigger = confirm("보안코드를 변경하시겠습니까?");
      if (!trigger) {
        return;
      }
      const res = await companyOtpUpdate(req);
      if (res.resultCode === "0") {
        alert("보안코드 변경 완료");
        this.setTableData();
        this.otp.new = "";
        this.otp.check = "";
      } else {
        alert("보안코드 변경 실패, 다시 시도해주세요.");
      }
    },
    inputTypeToggle(type) {
      const target = this.$refs[type];
      const targetType = target.getAttribute("type");

      console.log(targetType);
      if (targetType === "text") {
        target.setAttribute("type", "password");
        this[`${type}Text`] = "보기";
      } else if (targetType === "password") {
        target.setAttribute("type", "text");
        this[`${type}Text`] = "숨기기";
      }
    },
    getSiteInfo() {
      const siteInfoCookie = getSiteData()
      if (siteInfoCookie) {
        const siteInfo = JSON.parse(siteInfoCookie)
        return siteInfo
      }
    },
    async setTableData() {
      this.emitter.emit('Loading', true)
      const siteInfo = this.getSiteInfo();
      const req = {
        memId: siteInfo.siteId,
        siteId: siteInfo.siteId
      };
      const res_pDetail = await companyDetail(req);
      const companyInfo = res_pDetail.data.memberDetail;
      const res_siteAcc = await settingAccount(req);
      const siteAccInfo = res_siteAcc.data.account;
      this.siteData = lodash.merge({}, companyInfo, siteAccInfo);
      for (const key in this.siteData) {
        if (key.indexOf("memBlackCnt") > -1) {
          const keySplit = key.split("memBlackCnt");
          const blackCnt = Number(this.siteData[`memBlackCnt${keySplit[1]}`]) + Number(this.siteData[`memCnt${keySplit[1]}`]);
          const keyText = `memTotalCnt${keySplit[1]}`;
          this.siteData[keyText] = blackCnt;
        }
        console.log(key);
        console.log(key.indexOf("Amt") > -1);
        if (key.indexOf("Amt") > -1) {
          const item = Number(this.siteData[key]);
          if (!isNaN(item)) {
            this.siteData[key] = numberWithCommas(item);
          }
        }
      }
      this.emitter.emit('Loading', false)
    }
  },
  async created() {
    this.setTableData();
  }
};
</script>

<style scoped>
.mt-15 {margin-top: 15px;}
.searchwrap {padding: 35px 37px;}
table {width: 100%;}
.page-content {box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);background-color: #fff;}
.page-contents {display: flex;gap: 50px;}
.page-contents-wrap {margin-bottom: 0;border: 0;width: 228px;}
.page-contents-wrap:nth-child(1) .item-title,
.page-contents-wrap:nth-child(2) .item-title {border-right: 1px solid #d8d8d8;height: 42px;}
.sub-title {font-size: 15px;
    font-weight: bold;
    color: #3d3d3d;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 15px 14px;}
.sub-title .btn-layout {padding: 4px 9px;background-color: #fff9f0;color: #3d3d3d;}
.sub-title > h3 {display: flex;height: 100%;align-items: center;}
.item-row-container:first-child {border-top: 1px solid #d8d8d8;}
.item-row-container {
    border: 1px solid #d8d8d8;
    border-top: 0;
    margin: 0;
    padding: 12px 13px 12px 8px;
    box-sizing: border-box;
}
.item-wrap {display: flex;align-items: center;justify-content: space-between;color: #585963;font-size: 12px;}
.item-wrap > div.item-title {height: auto !important;border: 0 !important;}
.item-wrap > div.item-content {justify-content: end;}
.item-wrap > div.item-content input[type=password] {width: 100px;height: 22px;border-radius: 2px;box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);border: solid 1px #898989;background: #fff;}

.content td {
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
  border-bottom: solid 1px #ddd;
}
.subContent {
  max-height: 0;
  overflow: hidden;
}
.subContent.open {
  padding: 5px 5px;
  max-height: unset;
}
.subContent th,
.subContent td {
  border: 1px solid #fff;
  padding: 4px 10px;
  height: 35px;
  box-sizing: border-box;
}
.subContent th {
  background: #ffe5de;
  color: #000;
  font-weight: 700;
}
.btn-subConShow {
  position: absolute;
  border: 2px solid #df4946;
  border-radius: 3px;
  padding: 4px;
  background: #df4946;
  color: #fff;
  font-size: 0.8em;
  transform: translateY(-50%);
  top: 50%;
  right: 8px;
  cursor: pointer;
}
.btn-subConShow:hover {
  background: #fff;
  color: #df4946;
}
.item i {
  font-size: 14px;
  margin-right: 4px;
  color: #686868;
}
.title-select-item {
  box-sizing: border-box;
  padding: 4px;
  font-size: 1em;
  width: 100%;
  border: 0;
}
.item-content select {
  border: 1px solid #353535;
}
textarea {
  width: 100%;
  min-height: 160px;
  border: 1px solid #18698b;
  resize: none;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 3px;
}
.textarea-container {
  display: flex;
  align-items: stretch;
  height: calc(100% - 44px);
}
.center button {
  margin: 10px 0 70px;
  font-size: 14px;
  width: 130px;
  height: 45px;
}
.center {
  text-align: center;
}

</style>
